var axios = require("axios").default;
const baseUrl = `https://api.ebsr.in/`;
// const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
// const token = localData.token
export default class AdverseReaction {
    async AdverseReaction_OD(data) {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}adverseReaction/fetch_adverse_reaction_od/${data}`,
                headers: {
                    'Content-type': 'application/json',
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    async AdverseReaction_OS(data) {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}adverseReaction/fetch_adverse_reaction_os/${data}`,
                headers: {
                    'Content-type': 'application/json',
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    async AllList() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}adverseReaction/all_adverse_reaction`,
                headers: {
                    'Content-type': 'application/json',
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
}